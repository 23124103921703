import BaseListing from './base-listing';

class ResourceListing extends BaseListing {
  constructor({ selector }) {
    const params = {
      selector,
      parent: 'section',
      path: '/resource-listings',
    };

    super(params);
  }
}

export default ResourceListing;
