class NavigationStrip {
  constructor({selector}) {
    this.selector = selector;
    this.navStrip = document.querySelector(this.selector);
    if (!this.navStrip) { return null; }

    NavigationStrip.startNavSlider(this.navStrip);
  }

  static startNavSlider(navStrip) {
    var slider = navStrip.querySelector('.strip__navigation');
    var isDown = false;
    var startX;
    var scrollLeft;

    slider.addEventListener('mousedown', function(e) {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', function() {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', function() {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', function(e) {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; // scroll-fast (3 x speed)
      slider.scrollLeft = scrollLeft - walk;
      // console.log(walk);
    });
  }
}

export default NavigationStrip;