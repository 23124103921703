import Helpers from '../lib/helpers';

class Search {
  constructor({
    searchContainerSelector,
    searchButtonsSelector,
    searchBarSelector,
    searchInputSelector,
  }) {
    this.searchContainerSelector = searchContainerSelector;
    this.searchButtonsSelector = searchButtonsSelector;
    this.searchBarSelector = searchBarSelector;
    this.searchInputSelector = searchInputSelector;

    if (!this.searchContainerSelector) { return null; }
    if (!this.searchButtonsSelector) { return null; }
    if (!this.searchBarSelector) { return null; }

    // Show / hide the search bar
    const searchContainers = document.querySelectorAll(this.searchContainerSelector);

    if (!searchContainers) { return null; }

    Helpers.forEach(searchContainers, (el) => {
      const searchButton = el.querySelector(this.searchButtonsSelector);
      const searchBar = el.querySelector(this.searchBarSelector);

      searchButton.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (searchBar) {
          if (searchBar.getAttribute('data-visible') != null) {
            searchBar.removeAttribute('data-visible');
            el.setAttribute('aria-expanded', 'false');
            searchButton.removeAttribute('data-visible');
          } else {
            searchBar.setAttribute('data-visible', '');
            searchButton.setAttribute('data-visible', '');
            el.setAttribute('aria-expanded', 'true');
            searchBar.querySelector(this.searchInputSelector).focus();
          }
        }
      });
    });
  }
}

export default Search;
