import Helpers from '../lib/helpers';

class BaseListing {
  showing = 0;

  constructor({
    selector,
    parent,
    path,
    vars = {},
  }) {
    this.listing = document.querySelector(selector);
    if (!this.listing) { return; }

    this.path = path;
    this.button = this.listing.closest(parent).querySelector('button');
    this.attributeKey = selector.replace(/\[|\]/g, '');
    this.resource = this.listing.getAttribute(this.attributeKey);
    this.vars = vars;

    this.init();
  }

  init() {
    this.fetchMore();

    this.button.addEventListener('click', (e) => {
      e.preventDefault();

      this.fetchMore();
    });
  }

  fetchMore() {
    this.fetch({ offset: this.showing })
      .then((resp) => this.handleResponse(resp));
  }

  handleResponse(resp) {
    const respObj = JSON.parse(resp);
    this.showing += respObj.count;
    this.listing.innerHTML += respObj.pages;
    Helpers.addClass(this.button, 'visible');
    if (respObj.last || respObj.pages.length < 1) {
      this.button.parentNode.removeChild(this.button);
    }
  }

  fetch(data = {}) {
    let dataStr = new URLSearchParams({ ...this.vars, ...data }).toString();

    if (dataStr.length > 0) {
      dataStr = `?${dataStr}`;
    }

    return new Promise((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest();

      xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === XMLHttpRequest.DONE) {
          if (xmlhttp.status === 200) {
            resolve(xmlhttp.response);
          } else {
            reject(xmlhttp.response);
          }
        }
      };
      const fullPath = `${this.path + (this.resource ? `/${this.resource}` : '')}.json${dataStr}`;

      xmlhttp.open('GET', fullPath, true);
      xmlhttp.send();
    });
  }
}

export default BaseListing;
