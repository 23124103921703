import BaseListing from './base-listing';

class AuthorArticleListing extends BaseListing {
  constructor({ selector }) {
    const params = {
      selector,
      parent: 'section',
      path: '/author-articles',
    };

    super(params);
  }
}

export default AuthorArticleListing;
