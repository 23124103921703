import BaseListing from './base-listing';

class EventWebinarListing extends BaseListing {
  constructor({ selector, direction }) {
    const siteId = document.querySelector('body').dataset.filterLang;
    const params = {
      selector,
      parent: 'section',
      path: '/event-webinar-listings',
      vars: { lang: siteId, dir: direction },
    };

    super(params);
  }
}

export default EventWebinarListing;
