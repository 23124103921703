import Helpers from '../lib/helpers';
import Modal from '../lib/modal';

class Modals {
  constructor() {
    let body = document.querySelector('body');

    /**
    * Add a class to body when its a touch device
    * This is needed for the modal system to open maps and videos directly on mobile
    * instead of opening them in a new modal window
    */
    // define boolean var as false initially as no touch has happened yet
    let isTouch = false;

    // add class to body if isTouch is true
    const checkTouchBoolean = () => {
      // this will only run once
      if (isTouch) {
        // console.log("is-touch class added");
        Helpers.addClass(body, 'is-touch-device');
        // class now added to body, so can remove the event listener
        window.removeEventListener('touchstart', touchStartInit, { passive: true });
      }
    };

    // touch device being used, set isTouch to true now
    let touchStartInit = () => {
      isTouch = true;
      checkTouchBoolean();
    };
    window.addEventListener('touchstart', touchStartInit, { passive: true });

    /**
    * Setup our modal types
    */
    Helpers.forEach(document.querySelectorAll('[data-modal-video]'), (el) => {
      const videourl = el.getAttribute('data-modal-video');
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        new Modal({
          modalType: 'video',
          modalTitle: 'Video',
          content: videourl
        });
      });
    });

    Helpers.forEach(document.querySelectorAll('[data-modal-share]'), (el) => {
      const shareurl = el.getAttribute('data-modal-share');
      const sharecontent = el.getAttribute('data-modal-content');
      const shareplatform = el.getAttribute('data-modal-platform');

      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        new Modal({
          modalType: 'share',
          modalTitle: 'Share this page',
          content: sharecontent,
          sharingUrl: shareurl
        });
      });
    });

    Helpers.forEach(document.querySelectorAll('[data-modal-ajax]'), (el) => {
      const shareurl = `${el.getAttribute('href')}?layout=blank`;
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        new Modal({
          modalType: 'ajax',
          modalTitle: 'AJAX DATA',
          content: shareurl
        });
      });
    });

    Helpers.forEach(document.querySelectorAll('[data-modal-html]'), (el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();

        new Modal({
          modalType: 'html',
          modalTitle: 'html',
          content: el.getAttribute('data-modal-html')
        });
      });
    });

    // look inside the element that was clicked...
    // scrape data inside it with data-modal-scrape-content
    // output scraped data to a modal window
    Helpers.forEach(document.querySelectorAll('[data-modal-scrape]'), (el) => {
      const content = el.querySelector('[data-modal-scrape-content]');
      if (content) {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          new Modal({
            modalType: 'scrape',
            modalTitle: 'scrape',
            content: content
          });
        });
      }
    });
  }
}

export default Modals;
