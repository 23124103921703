import Helpers from '../lib/helpers';

class StickyNav {
  constructor({
    header, primaryNav, main, body,
  }) {
    this.header = document.querySelector(header);
    this.primaryNav = document.querySelector(primaryNav);
    this.main = document.querySelector(main);
    this.body = document.querySelector(body);

    if (!this.header) { return null; }
    if (!this.primaryNav) { return null; }
    if (!this.main) { return null; }
    if (!this.body) { return null; }

    // Run sticky nav on page load in case we are not at the top
    this.run();

    // Run sticky nav on scroll too
    const stickyNavDebouncer = Helpers.debounce(() => {
      this.run();
    }, 20);
    window.addEventListener('scroll', stickyNavDebouncer, { passive: true });
  }

  run() {
    let navIsVisible;

    const scroll = window.pageYOffset;
    const scrollLength = 380; // px distance before sticky kicks in

    if (scroll >= scrollLength && !this.body.classList.contains('has-sticky-nav')) {
      // add a class to the elements
      this.header.classList.add('is-sticky');
      this.primaryNav.classList.add('is-sticky');
      this.main.classList.add('is-sticky');
      this.body.classList.add('has-sticky-nav');

      // declare that the header is now visible
      navIsVisible = true;
    } else if (scroll < scrollLength && this.body.classList.contains('has-sticky-nav')) {
      // remove classes from the elements
      this.header.classList.remove('is-sticky');
      this.primaryNav.classList.remove('is-sticky');
      this.main.classList.remove('is-sticky');
      this.body.classList.remove('has-sticky-nav');

      // declare that the header is now hidden
      navIsVisible = false;
    }
  }
}

export default StickyNav;
