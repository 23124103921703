class Alert {
  constructor({selector}) {
    this.selector = selector;

    if (!this.selector) { return null;}

    this.banner = document.querySelector(this.selector);
    this.startAlertBanner();
  }

  startAlertBanner() {
    var alertBanner = this.banner;

    if (alertBanner) {
      var alertVersion = alertBanner.getAttribute('data-alert-version');
      var alertCloseButton = alertBanner.querySelector('[data-alert-close]');
      var cookieName = '_appsanywhere_alert';
      var cookieDurationInSeconds = (60 * 60 * 24 * 3);

      alertCloseButton.addEventListener('click', function () {
        dismissAlert(alertBanner, alertVersion, cookieName, cookieDurationInSeconds);
        document.body.classList.remove('has-alert-message');
      });

      if (!readCookie(cookieName) || (readCookie(cookieName) !== alertVersion)) {
        alertBanner.removeAttribute('style'); // Fix IE11 bug https://stackoverflow.com/questions/39851544/assignment-to-read-only-properties-is-not-allowed-in-strict-mode-ie11
        document.body.classList.add('has-alert-message');
      }
    }

    var dismissAlert = function(alertBanner, alertVersion, cookieName, cookieDurationInSeconds) {
      setCookie(cookieName, alertVersion, cookieDurationInSeconds);
      alertBanner.setAttribute('style', 'display: none;');
    }

    function setCookie(cookieName, value, secondsUntilExpire) {
      var date = new Date();
      date.setTime(date.getTime() + (secondsUntilExpire * 1000));
      var expires = "; expires=" + date.toGMTString();
      document.cookie = escape(cookieName) + "=" + escape(value) + expires + "; path=/";
    }

    function readCookie(name) {
      var nameEQ = escape(name) + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return unescape(c.substring(nameEQ.length, c.length));
      }
      return null;
    }

  };
}

export default Alert;
